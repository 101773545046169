import React from "react";
import Header from "../Sections/Header";
import {
	Container,
	Row,
	Col,
	Form,
	FormControl,
	FormGroup
} from "react-bootstrap";

class Newsletter extends React.Component {
	constructor(props) {
		super();
		this.onEmailChange = this.onEmailChange.bind(this);
		this.onNewsletter = this.onNewsletter.bind(this);
		this.state = {
			email: "",
			message: "",
			errMessage: ""
		};
	}
	onEmailChange = event => {
		this.setState({ email: event.target.value });
	};
	onNewsletter = () => {
		console.log(this.state);
		fetch("https://pure-peak-96458.herokuapp.com/newsletter", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				email: this.state.email
			})
		})
			.then(res => res.json())
			.then(data => {
				if (data.success === true) {
					this.setState({ message: data.message });
				} else if (data.success === false) {
					this.setState({ errMessage: data.message });
				}
			})
			.catch(err => {
				this.setState({ isLoading: false });
				this.setState({
					errorMessage:
						"There was a network error. Check your network connectivity and try again"
				});
			});
	};

	render() {
		return (
			<div className="calculate-profit">
				<Container>
					<Header
						paragraph="sign up to our newsletter to participate in our promotional offers and discount programmes"
						heading="Join our Newsletter"
					/>
					<div className="pb-2 text-danger">
						<p>{this.state.errMessage}</p>
					</div>
					<div className="pb-2 text-info">
						<p>{this.state.message}</p>
					</div>
					<Row>
						<Col
							xl={8}
							lg={8}
							className="d-xl-flex d-lg-flex d-block align-items-center"
						>
							<div className="calculate-area">
								<Form>
									<Row className="justify-content-center">
										<Col xl={6} lg={6} md={6}>
											<FormGroup>
												<FormControl
													type="email"
													name="email"
													className="form-control"
													id="newsletter-email"
													placeholder="Enter Your Email"
													onChange={this.onEmailChange}
												/>
											</FormGroup>
										</Col>
										<Col
											md={4}
											lg={3}
											xl={3}
											className="d-md-block d-flex align-items-end"
										>
											<button
												className="btn btn-primary"
												onClick={this.onNewsletter}
											>
												Submit
											</button>
										</Col>
									</Row>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default Newsletter;
