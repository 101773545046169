import React from "react";
import Header from "../Sections/Header";
import Invest from "./Invest";
import { Row, Container } from "react-bootstrap";

const Investment = () => {
  return (
    <div className="plan">
      <Container className="container">
        <Header
          paragraph="Flexible Bitcoin investment plan, Choose any bitcoin plan that suites your financial capability"
          heading="Bitcoin Investment Plan"
        />
        <Row className="d-flex justify-content-center align-items-center">
          <Invest
            plan="BASIC PLANS"
            days="14"
            range="$100 - $1,000"
            percent="200%"
            effect="fade-up"
            duration="1000"
          />
          <Invest
            plan="SILVER PLANS"
            days="10"
            range="$1,000 - 5,000"
            percent="250%"
            effect="fade-up"
            duration="1500"
          />
          <Invest
            plan="GOLD PLANS"
            days="7"
            range="$5,000 - $10,000"
            percent="250%"
            effect="fade-up"
            duration="2000"
          />
          <Invest
            plan="DIAMOND PLANS"
            days="4"
            range="$10,000 - $1,000,000"
            percent="300%"
            effect="fade-up"
            duration="2500"
          />
        </Row>
      </Container>
    </div>
  );
};
export default Investment;
