import React, { Component } from "react";
import Header from "./components/Header/Header";
import Banner from "./components/Banner/Banner";
import Statistics from "./components/Statistics/Statistics";
import About from "./components/About/About";
import Features from "./components/Features/Features";
import Investment from "./components/Investment/Investment";
import Newsletter from "./components/Newsletter/Newsletter";
import Referral from "./components/Referral/Referral";
import Footer from "./components/Footer/Footer";
import Testimony from "./components/Testimony/Testimony";
import Steps from "./components/Steps/Steps";
import Contact from "./components/Contact/Contact";

export default class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Banner />
        <Steps />
        <About class="text" link="Know More" />
        <Statistics />
        <Newsletter />
        <Features />
        <Investment />
        <Contact />
        <Testimony />
        <Referral />
        <Footer />
      </div>
    );
  }
}
