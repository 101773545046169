import React from "react";
import Navigation from "../../../components/Account/Navigation/Navigation";
import "../../../assets/Account/css/style.css";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../../components/Account/Navigation/Footer";
import Form from "react-bootstrap/Form";
import API from "../../../API";
import Upload from "../../../components/Account/Upload";

class Deposit extends React.Component {
  constructor() {
    super();
    this.state = {
      errorMessage: "",
      amount: 0,
      plan: "Basic",
      type: "Deposit",
      wallet: "",
    };

    this.onPlanChange = this.onPlanChange.bind(this);
    this.onAmountChange = this.onAmountChange.bind(this);
  }

  onPlanChange = (event) => {
    this.setState({ plan: event.target.value });
  };

  onAmountChange = (event) => {
    this.setState({ amount: event.target.value });
  };
  componentDidMount() {
    API.get("admin/wallet/address")
      .then((res) => {
        const data = res.data;
        this.setState({ wallet: data });
      })
      .catch((err) => {
        this.setState({
          errorMessage: "There's has been an error getting wallet",
        });
      });
  }

  render() {
    const { wallet, amount, type, plan } = this.state;
    return (
      <div className="deposit" style={{ background: "#1d1d52" }}>
        <Navigation />

        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={9}>
              <Card className="account-cards text-center">
                <Card.Body>
                  <Card.Title className="mb-2 text-left">Deposit</Card.Title>
                  <Form>
                    <Form.Group controlId="depositSelect">
                      <Form.Label>Select Plan</Form.Label>
                      <Form.Control as="select" onChange={this.onPlanChange}>
                        <option value="Basic">
                          BASIC PLANS-14days($100 - $1,000)
                        </option>
                        <option value="Silver">
                          SILVER PLAN-10days($1,000 - $5,000)
                        </option>{" "}
                        <option value="Gold">
                          GOLD PLAN-6days($5,000 - $10,000)
                        </option>
                        <option value="Diamond">
                          DIAMOND PLAN-3days($10,000 - $1,000,000)
                        </option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="depositAmount">
                      <Form.Label>Amount Deposited</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={this.onAmountChange}
                        placeholder="Enter Amount"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Card.Title>
                        PLEASE SEND EXACT FUNDS TO HARD WALLET BELOW
                      </Card.Title>
                      <Card.Title>{wallet}</Card.Title>
                    </Form.Group>

                    <span className="note-text text-danger">
                      <i className="fas fa-times-circle text-danger"></i> Do not
                      deposit less than the minimum required amount in each plan
                    </span>
                    <br />
                    <Card.Text>
                      Copy the Wallet Address & Choose your means of payment
                    </Card.Text>
                    <Row>
                      <Col>
                        <span>
                          <button className="btn btn-primary">
                            <a
                              href="https://payments.changelly.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "white" }}
                            >
                              <li className="fab fa-btc "></li> Via CHANGELLY
                            </a>
                          </button>
                        </span>
                        <Card.Text>
                          <strong>Credit or Debit Card</strong>
                        </Card.Text>
                      </Col>
                      <Col>
                        <span>
                          <button
                            className="btn"
                            style={{ color: "black", background: "yellow" }}
                          >
                            <a
                              href="https://play.google.com/store/apps/details?id=io.yellowcard.app"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "black", background: "yellow" }}
                            >
                              <li className="fab fa-btc "></li> Via Yellow Card
                            </a>
                          </button>
                        </span>
                        <Card.Text>
                          <strong>Credit or Debit Card</strong>
                        </Card.Text>
                      </Col>

                      <Col>
                        <span>
                          <button className="btn btn-primary">
                            <a
                              href="https://play.google.com/store/apps/details?hl=en&id=co.bitx.android.wallet"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "white" }}
                            >
                              <li className="fab fa-btc "></li> Via LUNO
                            </a>
                          </button>
                        </span>
                        <div>
                          <strong>Luno Account</strong>
                        </div>
                      </Col>
                    </Row>

                    <p>
                      <strong>Note</strong>: It may take 12-24 hours for your
                      transaction to be confirmed
                    </p>
                    <Form.Group>
                      <Upload type={type} plan={plan} amount={amount} />
                    </Form.Group>
                    <Card.Title>NOTE: Upload payment receipt</Card.Title>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Deposit;
