import React from "react";
import investor from "../../assets/img/investor.svg";
import online from "../../assets/img/online.svg";
import deposit from "../../assets/img/deposit.png";
import withdrawal from "../../assets/img/depositt.svg";
import Statistic from "./Statistic";
import Header from "../Sections/Header";
import { Row, Container } from "react-bootstrap";

class Statistics extends React.Component {
  constructor() {
    super();
    this.state = {
      amount1: 0,
      amount2: 0,
      amount3: 0,
      amount4: 0,
    };
  }

  increaseNumber = () => {
    setInterval(() => {
      if (this.state.amount1 < 1908) {
        this.setState({ amount1: this.state.amount1 + 3 });
      }
      if (this.state.amount2 < 7398) {
        this.setState({ amount2: this.state.amount2 + 10 });
      }
      if (this.state.amount3 < 52992) {
        this.setState({ amount3: this.state.amount3 + 24 });
      }
      if (this.state.amount4 < 40320) {
        this.setState({ amount4: this.state.amount4 + 24 });
      }
    }, 5);
  };
  componentDidMount() {
    this.increaseNumber();
  }
  render() {
    return (
      <div className="statics">
        <Container>
          <Header
            paragraph="Our statistics comprise of all the Bitcoin Investment activities
								in our system"
            heading="Our Statics"
          />

          <Row className="d-flex justify-content-center align-items-center">
            <Statistic
              tag="Total Online"
              max="1,098"
              amount={this.state.amount1}
              src={online}
            />

            <Statistic
              tag="Total Investors"
              max="7,398"
              amount={this.state.amount2}
              src={investor}
            />
            <Statistic
              tag="Total Deposit"
              max="49,223"
              amount={this.state.amount3}
              src={deposit}
            />
            <Statistic
              tag="Total Withdrawals"
              max="32,837"
              amount={this.state.amount4}
              src={withdrawal}
            />
          </Row>
        </Container>
      </div>
    );
  }
}

export default Statistics;
