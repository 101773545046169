import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Trading from "./Trading";
import HowToBegin from "./HowToBegin";
import MyAccount from "./MyAccount";

export default class Faq extends Component {
	render() {
		return (
			<div className="faq" data-aos="zoom-in">
				<Tabs
					defaultActiveKey="begin"
					id="uncontrolled-tab-example"
					style={{}}
					className="d-flex justify-content-center text-white font-weight-bolder"
				>
					<Tab eventKey="account" title="My Account" className="py-5 p-5">
						<MyAccount />
					</Tab>
					<Tab
						eventKey="begin"
						title="How To Begin"
						className="py-5 p-5 tab-content"
					>
						<HowToBegin />
					</Tab>
					<Tab eventKey="trading" title="Trading" className="py-5 p-5">
						<Trading />
					</Tab>
				</Tabs>
			</div>
		);
	}
}
