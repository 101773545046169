import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Steps = () => {
  return (
    <div className="steps">
      <Container className="steps-content">
        <Row className="justify-content-center">
          <Col md={4} className="m-auto pb-5 m-5 text-left single-step">
            <h1>1. Register</h1>
            <p>Choose the right account type and get started with an account</p>
          </Col>
          <Col md={4} className="m-auto pb-5 m-5 text-left single-step">
            <h1>2. Fund</h1>
            <p>
              Use your credit card or a range of global bank deposit methods to
              fund
            </p>
          </Col>
          <Col md={4} className="m-auto pb-5 m-5 text-left single-step">
            <h1>3. First-Grade Services</h1>
            <p>
              And rest let our expert traders offer you the best of their
              services.
            </p>
          </Col>
        </Row>
        <Row className="my-auto">
          <Col>
            <button className="blueBtn">
              <a href="/register">Get Started</a>
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Steps;
