import React, { Component } from "react";
import Header from "../../components/Header/Header";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import Faq from "../.././components/Faq/Faq";
import Footer from "../../components/Footer/Footer";

export default class FAQ extends Component {
	render() {
		return (
			<div>
				<Header />
				<BreadCrumb title="FAQ" />
				<Faq />
				<Footer />
			</div>
		);
	}
}
