import React from "react";
import { Col } from "react-bootstrap";

export default function Statistic(props) {
  return (
    <Col
      xs={10}
      sm={6}
      md={3}
      lg={3}
      xl={3}
      data-aos="fade-up"
    >
      <div className="single-statics">
        <div className="after-before">
          <div className="part-img">
            <img src={props.src} alt="" />
          </div>
          <div className="part-text">
            <span className="number">{props.amount}</span>
            <span className="title">{props.tag}</span>
          </div>
        </div>
      </div>
    </Col>
  );
}
