import React, { Component } from "react";
import Header from "../../components/Header/Header";
import BreadCrumb from "../.././components/BreadCrumb/BreadCrumb";
import Register from "../../components/Register/Register";
import Footer from "../../components/Footer/Footer";

export default class RegisterPage extends Component {
  render() {
    return (
      <>
        <Header />
        <BreadCrumb title="Sign Up" />
        <Register />
        <Footer />
      </>
    );
  }
}
