import React, { Component } from "react";
import Header from "../.././components/Header/Header";
import BreadCrumb from "../.././components/BreadCrumb/BreadCrumb";
import Login from "../.././components/Login/Login";
import Footer from "../../components/Footer/Footer";

export default class LoginPage extends Component {
	render() {
		return (
			<div>
				<Header />
				<BreadCrumb title="Login" />
				<Login />
				<Footer />
			</div>
		);
	}
}
