import React from "react";
import Header from "../Sections/Header";
import { Container, Row, Col } from "react-bootstrap";

class Newsletter extends React.Component {
  constructor(props) {
    super();
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onNewsletter = this.onNewsletter.bind(this);
    this.state = {
      email: "",
      message: "",
      errMessage: "",
    };
  }
  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  onNewsletter = () => {
    console.log(this.state);
    fetch("https://pure-peak-96458.herokuapp.com/newsletter", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({ message: data.message });
        } else if (data.success === false) {
          this.setState({ errMessage: data.message });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        this.setState({
          errorMessage:
            "There was a network error. Check your network connectivity and try again",
        });
      });
  };

  render() {
    return (
      <div className="calculate-profit">
        <Container>
          <Header
            paragraph="Our financial advisers always help you"
            heading="Request a Call Back"
          />
          <div className="pb-2 text-danger">
            <p>{this.state.errMessage}</p>
          </div>
          <div className="pb-2 text-info">
            <p>{this.state.message}</p>
          </div>
          <Row>
            <Col
              xl={8}
              lg={8}
              className="d-xl-flex d-lg-flex d-block align-items-center"
            >
              <div className="calculate-area">
                <Row className="justify-content-center text-white">
                  <Col xl={6} lg={6} md={6}>
                    <p>
                      Interested in making a right decision to invest with us
                      but don't know how to decide which plan works best for
                      you?
                    </p>
                    <p>
                      <strong>We're here just for you.</strong>
                    </p>
                  </Col>
                  <Col md={4} lg={6} xl={6}>
                    <p>Mon - Sat 10:00am - 6:00pm</p>
                    <p>Sun 11:00am - 3:00pm</p>
                    <p>Tel: +1(431)-301-5128</p>
                    <p>Mail: support@coinvestmentoptions.net</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Newsletter;
