import React, { Component } from "react";
import Header from "../../././components/Header/Header";
import BreadCrumb from "../.././components/BreadCrumb/BreadCrumb";
import Statistics from "../.././components/Statistics/Statistics";
import About from "../.././components/About/About";
import Footer from "../.././components/Footer/Footer";
import Partners from "../.././components/Partners/Partners";

export default class AboutPage extends Component {
	render() {
		return (
			<div>
				<Header />
				<BreadCrumb title="About" />
				<About />
				<Statistics />
				<Partners />
				<Footer />
			</div>
		);
	}
}
