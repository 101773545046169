import React from "react";
import { Col } from "react-bootstrap";

export default function Invest({
  effect,
  duration,
  plan,
  days,
  range,
  percent,
}) {
  return (
    <Col
      lg={3}
      md={3}
      sm={5}
      xs={10}
      className="single-plan"
      data-aos={effect}
      data-aos-duration={duration}
    >
      <h3>{plan}</h3>
      <div className="part-price">
        <span className="percent">{percent}</span>
      </div>
      <div className="part-feature">
        <ul>
          <li>
            Deposit <br />
            {range}
          </li>
          <li>Enhanced security</li>
          <li> Maturity - {days} Days</li>
          <li> 24/7Support</li>
        </ul>
      </div>
      <button className="blueBtn">
        <a href="/register">Start Now</a>
      </button>
    </Col>
  );
}
