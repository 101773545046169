import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Crypto from "../../Widgets/CryptoUpdate";
import Sell from "../../Widgets/Sell";
import Crypt from "../../Widgets/Crypt";
import Pagination from "../Pagination";
import Wallet from "../Wallet";

export default class Dashboard extends Component {
	render() {
		return (
			<div className="Dashboard">
				<Container>
					<Row>
						<Pagination name="Crypto" first="Dashboard" second="Crypto" />
					</Row>
					<Row>
						<Col md={4}>
							<Crypto />
						</Col>
						<Col md={4}>
							<Sell />
						</Col>
						<Col md={4}>
							<Crypt />
						</Col>
					</Row>
					<Wallet />
				</Container>
			</div>
		);
	}
}
