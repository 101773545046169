import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import Header from "../Sections/Header";

import test3 from "../.././assets/img/testimony-img/3.jpg";
import test5 from "../.././assets/img/testimony-img/5.jpg";
import test6 from "../.././assets/img/testimony-img/6.jpg";
import test7 from "../.././assets/img/testimony-img/7.jpg";

class Testimony extends React.Component {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      650: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1020: {
        items: 3,
      },
      1920: {
        items: 4,
      },
    },
  };

  render() {
    return (
      <div
        className="testimonial"
        style={{
          background: "linear-gradient(rgba(10, 13, 54, 0.9), rgb(15, 6, 9))",
        }}
      >
        <div className="container">
          <Header
            paragraph="Join our happy community members to invest in bitcoin and
									share your testimonies too"
            heading="Testimonies"
          />
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12">
              <OwlCarousel
                className=" owl-theme owl-responsive auto-width "
                responsiveClass={true}
                style={{ color: "black" }}
                loop={true}
                margin={10}
                dots={false}
                autoplay={true}
                autoplayTimeout={5000}
                items={4}
                responsive={this.state.responsive}
              >
                <div className="slide-item clone ">
                  <div className="single-testimonial">
                    <div className="part-img">
                      <img src={test3} id="first" height="100%" alt="" />
                    </div>
                    <div className="part-text">
                      <p>
                        ❝ Have been investing in different platforms but for
                        what you have offered and at the rate, you're the best
                        out there. Let's make money ❞
                      </p>
                      <span className="user-name">Britany Door</span>
                      <span className="user-profession">Customer</span>
                    </div>
                  </div>
                </div>
                <div className="slide-item clone ">
                  <div className="single-testimonial">
                    <div className="part-img">
                      <img src={test5} id="second" height="100%" alt="" />
                    </div>
                    <div className="part-text">
                      <p>
                        ❝ Investing in Coinvestment Options was at first risky but
                        now everyday is just as thrilling as the day before as
                        I've seen a means for extra paychecks . ❞
                      </p>
                      <span className="user-name">Madhak Aaron</span>
                      <span className="user-profession">Customer</span>
                    </div>
                  </div>
                </div>
                <div className="slide-item clone ">
                  <div className="single-testimonial">
                    <div className="part-img">
                      <img src={test6} id="third" height="100%" alt="" />
                    </div>
                    <div className="part-text">
                      <p>
                        ❝ Don't ever stop. I'd wish to join in this business as
                        I work as a self employed forex trader. Really amazing
                        experience and all. wow ❞
                      </p>
                      <span className="user-name">Pearl Morris</span>
                      <span className="user-profession">Customer</span>
                    </div>
                  </div>
                </div>
                <div className="slide-item clone ">
                  <div className="single-testimonial">
                    <div className="part-img">
                      <img src={test7} id="fourth" height="100%" alt="" />
                    </div>
                    <div className="part-text">
                      <p>
                        ❝many investment platforms exist but none like COINVEST
                        OPTIONS. I love your customer service as I hardly
                        have to wait to get my issues resolved ❞
                      </p>
                      <span className="user-name">Khash Moph</span>
                      <span className="user-profession">Customer</span>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Testimony;
