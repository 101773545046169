//Dependecies import
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/Account/css/style.css";
import "./assets/Admin/css/style.css";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";

//Landing Page Imports
import Investment from "./containers/Investment/InvestmentPage";
import About from "./containers/About/AboutPage";
import FAQ from "./containers/FAQ/FAQPage";
import Contact from "./containers/Contact/ContactPage";
import Register from "./containers/Register/RegisterPage";
import Login from "./containers/Login/LoginPage";
import App from "./App";
//Account Imports
import Dashboard from "./containers/Account/Dashboard/DashboardPage";
import Withdrawal from "./containers/Account/Withdrawal/WithdrawalPage";
import Transactions from "./containers/Account/Transactions/TransactionPage";
import Settings from "./containers/Account/Settings/SettingsPage";
import Wallet from "./containers/Account/Wallet/WalletPage";
import Deposit from "./containers/Account/Deposit/DepositPage";
import InvestmentPlans from "./containers/Account/Investment/InvestmentPage";
//Admin Imports
import Admin from "./containers/Admin/Dashboard";
import Investors from "./containers/Admin/Investors";
import Edit from "./containers/Admin/Edit";
import Email from "./containers/Admin/Email";
import UserTransactions from "./containers/Admin/Transactions";
//Hidden Route Imports
import Recover from "./containers/Hidden/Recover/Recover.js";
import Verify from "./containers/Hidden/Verify/Verify.js";
import Privacy from "./containers/Hidden/Privacy";

class Routes extends Component {
	constructor() {
		super();
		AOS.init();
	}

	componentDidUpdate() {
		AOS.refresh();
	}
	render() {
		return (
			<Router>
				<div className="OneAboveAll">
					<Switch>
						{/* //Landing Page Routes */}
						<Route exact path="/" component={App} />
						<Route exact path="/investment" component={Investment} />
						<Route exact path="/register" component={Register} />
						<Route exact path="/login" component={Login} />
						<Route exact path="/contact" component={Contact} />
						<Route exact path="/faq" component={FAQ} />
						<Route exact path="/about" component={About} />
						{/* //Account Routes */}
						<Route exact path="/account/dashboard" component={Dashboard} />
						<Route exact path="/account/deposit" component={Deposit} />
						<Route exact path="/account/Wallet" component={Wallet} />
						<Route
							exact
							path="/account/investment"
							component={InvestmentPlans}
						/>
						<Route exact path="/account/settings" component={Settings} />
						<Route
							exact
							path="/account/transactions"
							component={Transactions}
						/>
						<Route exact path="/account/withdrawal" component={Withdrawal} />

						{/* //Admin Routes */}

						<Route exact path="/admin" component={Admin} />
						<Route exact path="/admin/investors" component={Investors} />
						<Route exact path="/admin/edit" component={Edit} />
						<Route exact path="/admin/email" component={Email} />
						<Route exact path="/admin/transactions" component={UserTransactions} />
						{/* //Hidden Routes */}
						<Route exact path="/recover" component={Recover} />
						<Route exact path="/privacy" component={Privacy} />
						<Route exact path="/verify" component={Verify} />
					</Switch>
				</div>
			</Router>
		);
	}
}

export default Routes;
