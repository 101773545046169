import React, { Component } from "react";
import Header from "../../../components/Header/Header";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Recover from "../../../components/Hidden/Recover"
import Footer from "../../../components/Footer/Footer";

export default class LoginPage extends Component {
	render() {
		return (
			<div>
				<Header />
				<BreadCrumb title="Recover" />
				<Recover />
				<Footer />
			</div>
		);
	}
}
