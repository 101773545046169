import React from "react";
import security from "../../assets/img/security.svg";
import global from "../../assets/img/global.svg";
import customer from "../../assets/img/customer-service.svg";
import packages from "../../assets/img/packages.svg";
import fast from "../../assets/img/fast.svg";
import navigation from "../../assets/img/navigation.svg";
import { Row, Container } from "react-bootstrap";

import Header from "../Sections/Header";
import Feature from "./Feature";

const Features = () => {
  return (
    <div className="features">
      <Container>
        <Header
          heading="Awesome Features"
          paragraph="We have some notable features that make us the most trustworthy BTC investment platform"
        />
        <Row className="d-flex justify-content-center align-items-center">
          <Feature
            paragraph="We are security conscious and value your data. The entire
									system is protected with Comodo"
            heading="We're Secure"
            src={security}
            effect="fade-up"
          />
          <Feature
            paragraph="Our payment system is fully automated to avoid delay in withdrawal"
            heading="We're Fast"
            src={fast}
            effect="fade-up"
          />
          <Feature
            paragraph="Our system is well detailed to enable easy access to all our
									services and packages"
            heading="Easy Navigation"
            src={navigation}
            effect="fade-up"
          />
          <Feature
            paragraph="We are always live. We use human support system to reply to
									our enquiries 24/7"
            heading="24/7 Support"
            src={customer}
            effect="fade-up"
          />
          <Feature
            paragraph="The financial strength of everybody is not the same, that's why we have flexible plans to choose from"
            heading="Good Packages"
            src={packages}
            effect="fade-up"
          />
          <Feature
            paragraph="We support only crypto currency to avoid the barrier of fiat in some countries"
            heading="We're Global"
            src={global}
            effect="fade-up"
          />
        </Row>
      </Container>
    </div>
  );
};
export default Features;
