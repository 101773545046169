import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Navigation from "../../../components/Account/Navigation/Navigation";
import Footer from "../../../components/Account/Navigation/Footer";
import Chart from "../../../components/Widgets/CryptoUpdate";
import API from "../../../API";

export class WalletPage extends Component {
  constructor() {
    super();
    this.state = {
      first_name: "",
      last_name: "",
      plan: "",
      wallet: "",
      balance: 0,
    };
  }

  componentDidMount() {
    API.get("profile?token=" + window.localStorage.getItem("token"))
      .then((res) => {
        const data = res.data;

        if (data.success === false) {
          setTimeout(() => {
            window.location = "/";
          }, 1000);
        } else {
          this.setState({ total_deposit: data.total.deposit });
          this.setState({ balance: data.balance });
          this.setState({ plan: data.plan });
          this.setState({ last_name: data.last_name });
          this.setState({ wallet: data.wallet });
        }
      })
      .catch((err) => {
        this.setState({ errorMessage: "Your registration process failed" });
      });
  }
  onUpdate() {
    fetch();
  }
  render() {
    const { wallet, balance, plan } = this.state;
    return (
      <div className="wallet">
        <Navigation />
        <Container>
          <Row>
            <Col>
              <Card className={"card--container"} style={{ marginTop: "50px" }}>
                <Card.Body>
                  <Card.Title style={{}}>
                    <span>
                      <i className="fas fa-wallet text-danger"></i>
                    </span>
                    Wallet
                  </Card.Title>
                  <Card.Subtitle className="mb-2">
                    <span className="mr-auto">Coinvest Balance </span>
                  </Card.Subtitle>

                  <Card.Text>Available Balance:</Card.Text>

                  <h1 style={{ color: "green" }}>${balance}</h1>
                  <Card.Text className="text-muted">
                    Current Plan : {plan}
                    <br />
                    Book Balance : {balance}
                  </Card.Text>
                  <Card.Text className="mb-2 ">
                    <span className="mr-auto">Wallet Address: {wallet}</span>
                  </Card.Text>
                  <span>
                    <a href="/account/deposit">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                        name="submit"
                        href="/account/deposit"
                      >
                        <li className="fab fa-btc "></li> Deposit
                      </button>
                    </a>
                  </span>
                  <br />
                  <span>
                    <a href="/account/withdrawal">
                      <button
                        className="btn btn-danger btn-block"
                        type="submit"
                        name="submit"
                      >
                        <li className="fab fa-btc "></li>
                        Withdraw
                      </button>
                    </a>
                  </span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Chart />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default WalletPage;
