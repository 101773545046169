import React from "react";
import { Row, Col } from "react-bootstrap";

export default function Header(props) {
	return (
		<Row className="justify-content-center">
			<Col md={8} lg={6} data-aos-duration="2000" data-aos="zoom-out">
				<div className="section-title">
					<h1>{props.heading}</h1>
					<p>{props.paragraph}</p>
				</div>
			</Col>
		</Row>
	);
}
