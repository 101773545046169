import React from "react";

export default function index(props) {
	return (
		<div>
			<div
				className=" px-3 d-flex justify-content-between align-items-center "
				style={{
					height: "50px",
					width: "82vw",
					background: "white",
					borderRadius: "10px",
					marginTop: "20px"
				}}
			>
				<h1>{props.name}</h1>
				<div style={{ fontSize: "18px" }} className="text-danger">
					<span>Admin</span> / <span>{props.first}</span> /{" "}
					<span>{props.second}</span>
				</div>
			</div>
		</div>
	);
}
