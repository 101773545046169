import React, { Component } from "react";
import Navigation from "../../../components/Account/Navigation/Navigation";
import DashboardCard from "../../../components/Account/Card";
import "../../../assets/Account/css/style.css";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../../components/Account/Navigation/Footer";
import Form from "react-bootstrap/Form";
import Crypto from "../../../components/Widgets/CryptoUpdate";
import Sell from "../../../components/Widgets/Sell";
import API from "../../../API";
const NavLink = require("react-router-dom").NavLink;

export class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      first_name: "",
      last_name: "",
      plan: "",
      wallet: "",
      balance: 0,
      total_transaction: 0,
    };
  }

  UNSAFE_componentWillMount() {
    API.get("verify?token=" + window.localStorage.getItem("token"))
      .then((res) => {
        const data = res.data;
        if (data.success === false) {
          setTimeout(() => {
            window.location = "/";
          }, 1000);
        } else {
          this.setState({ last_name: data.last_name });
          this.setState({ first_name: data.first_name });
          this.setState({ total_transaction: data.total.total });
          this.setState({ balance: data.balance });
          this.setState({ plan: data.plan });
          this.setState({ wallet: data.wallet });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div className="dashboard">
        <Navigation />
        <div className="welcome-text text-center py-5">
          <Container>
            <h2>
              Welcome {this.state.first_name} {this.state.last_name}!{" "}
            </h2>
          </Container>
        </div>
        <Container>
          <Row className="first-card-row">
            <DashboardCard
              title={"Wallet"}
              top={this.state.balance}
              center={this.state.plan}
              bottom={this.state.balance}
            />
            <DashboardCard
              title={"Total Transactions"}
              top={this.state.total_transaction}
              center={this.state.plan}
              bottom={this.state.balance}
            />

            <Col md={4}>
              <Crypto />
            </Col>
          </Row>
          <Row className="second-card-row">
            <Col md={4}>
              <Card className="account-cards card--container">
                <Card.Body className="text-center">
                  <Card.Title className="mb-2 ">Deposit</Card.Title>

                  <Card.Text>Make a Deposit</Card.Text>
                  <Form>
                    <Form.Group>
                      <Form.Label>Select Plan</Form.Label>
                      <Form.Control as="select" onChange={this.onPlanChange}>
                        <option value="Basic">
                          BASIC PLANS-14days($100 - $1,000)
                        </option>
                        <option value="Silver">
                          SILVER PLAN-10days($1,000 - 5,000)
                        </option>{" "}
                        <option value="Gold">
                          GOLD PLAN-6days($5,000 - $10,000)
                        </option>
                        <option value="Diamond">
                          DIAMOND PLAN-3days($10,000 - $1,000,000)
                        </option>
                      </Form.Control>
                    </Form.Group>
                    <span>
                      <NavLink to="/account/deposit">
                        <button className="btn btn-primary btn-block">
                          <li className="fab fa-btc "></li> Deposit
                        </button>
                      </NavLink>
                    </span>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="account-cards card--container">
                <Card.Body className="text-center">
                  <Card.Title className="mb-2 ">Withdraw</Card.Title>

                  <Card.Text>Process a Withdrawal</Card.Text>
                  <Form>
                    <Form.Group>
                      <Form.Label>Select Wallet</Form.Label>
                      <Form.Control as="select">
                        <option value="1">Select Wallet or Add if none</option>
                        {this.state.wallet && (
                          <option value="2">{this.state.wallet}</option>
                        )}
                      </Form.Control>
                    </Form.Group>

                    <span>
                      <NavLink to="/account/withdrawal">
                        <button className="btn btn-danger btn-block">
                          <li className="fab fa-btc "></li> Withdraw
                        </button>
                      </NavLink>
                    </span>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Sell />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Dashboard;
