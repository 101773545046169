import React, { Component } from "react";
import Header from "../../components/Header/Header";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import Investment from "../../components/Investment/Investment";
import Referral from "../../components/Referral/Referral";
import Footer from "../../components/Footer/Footer";

export default class InvestmentPage extends Component {
  render() {
    return (
      <div>
        <Header />
        <BreadCrumb title="Investment" />
        <Investment />
        <Referral />
        <Footer />
      </div>
    );
  }
}
