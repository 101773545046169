import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-6 col-lg-6">
            <div className="copyright">
              <p>© Coinvestment Options (2017 - 2020) - All Rights Reserved</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
