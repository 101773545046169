import React, { Component } from "react";
import Header from "../../../components/Header/Header";
import Verify from "../../../components/Hidden/Verify";
import Footer from "../../../components/Footer/Footer";

export default class LoginPage extends Component {
	render() {
		return (
			<div>
				<Header />
				<Verify />
				<Footer />
			</div>
		);
	}
}
