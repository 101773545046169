import React from "react";
import { Col } from "react-bootstrap";

export default function Feature(props) {
  return (
    <Col xs={10} sm={5} md={4} lg={4}>
      <div
        className="single-feature"
        data-aos={props.effect}
        data-aos-duration="2000"
      >
        <div className="part-icon">
          <img src={props.src} alt="" />
        </div>
        <div className="part-text">
          <h2>{props.heading}</h2>
          <p>{props.paragraph}</p>
        </div>
      </div>
    </Col>
  );
}
