import React from "react";
import { Col, Row, Card, Form, Container } from "react-bootstrap";
import API from "../../../API";

export default class index extends React.Component {
	constructor() {
		super();
		this.state = {
			errorMessage: "",
			message: "",
			email: "",
			subject: "",
			title: "",
			body: "",
			id: 0
		};
		this.onChange = this.onChange.bind(this);
		this.onRequestInfo = this.onRequestInfo.bind(this);
		this.onSendEmail = this.onSendEmail.bind(this);
	}
	onChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};
	onSendEmail() {
		API.post("admin/email", {
			email: this.state.email,
			subject: this.state.subject,
			title: this.state.title,
			body: this.state.body
		})
			.then(res => {
				const data = res.data;
				if (data.success === true) {
					this.setState({ message: data.message });
				} else {
					this.setState({ errorMessage: data.message });
				}
			})
			.catch(err => {
				this.setState({ errorMessage: "There was a server error" });
			});
	}

	onRequestInfo() {
		API.post("admin/edit", { userId: this.state.id })
			.then(res => {
				const data = res.data;
				console.log(data);
				if (data) {
					this.setState({ email: data.email });
				} else {
					this.setState({ errorMessage: data.message });
					window.location.href = "/";
				}
			})
			.catch(err => {
				this.setState({ errorMessage: "There has been a server error" });
			});
	}

	render() {
		const { body, subject, email, title } = this.state;
		return (
			<div>
				<Container>
					<Row className="d-flex justify-content-center my-5">
						<Col md={10}>
							<Card data-aos="fade-up">
								<Card.Body>
									<Form>
										<Form.Group className="pb-1">
											<Form.Label
												className="font-weight-bold"
												htmlFor="first_name"
											>
												<span>
													<i className="fas fa-id-card text-info"></i>
												</span>{" "}
												Enter User Id: <span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												id="Id"
												type="text"
												name="id"
												placeholder="User ID from the Investors Table"
												onChange={this.onChange}
											/>
										</Form.Group>
									</Form>
									<button className="haveAccount" onClick={this.onRequestInfo}>
										Submit
									</button>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<Row className="d-flex justify-content-center ">
						<Col md={10}>
							<Card data-aos="fade-up">
								<Card.Body>
									<Form>
										<Form.Group className="pb-1">
											<Form.Label
												className="font-weight-bold"
												htmlFor="first_name"
											>
												<span>
													<i className="fas fa-id-card text-info"></i>
												</span>
												To:
												<span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												disabled
												id="email"
												type="text"
												name="email"
												value={email}
												placeholder="Investor Email"
												onChange={this.onChange}
											/>
										</Form.Group>
										<Form.Group className="pb-1">
											<Form.Label
												className="font-weight-bold"
												htmlFor="first_name"
											>
												<span>
													<i className="fas fa-id-card text-info"></i>
												</span>
												Enter Email Subject:
												<span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												id="subject"
												type="text"
												name="subject"
												value={subject}
												placeholder="Subject"
												onChange={this.onChange}
											/>
										</Form.Group>
										<Form.Group className="pb-1">
											<Form.Label
												className="font-weight-bold"
												htmlFor="first_name"
											>
												<span>
													<i className="fas fa-id-card text-info"></i>
												</span>
												Enter Email Title
												<span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												id="title"
												type="text"
												name="title"
												placeholder="Title"
												value={title}
												onChange={this.onChange}
											/>
										</Form.Group>
										<Form.Group className="pb-1">
											<Form.Label
												className="font-weight-bold"
												htmlFor="first_name"
											>
												<span>
													<i className="fas fa-id-card text-info"></i>
												</span>
												Enter Email Body:
												<span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												as="textarea"
												rows={5}
												id="body"
												type="text"
												name="body"
												placeholder="Body"
												value={body}
												onChange={this.onChange}
											/>
										</Form.Group>
									</Form>
									<button className="haveAccount" onClick={this.onSendEmail}>
										Send Email
									</button>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
