import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Header from "../../Sections/Header";

const index = () => {
  return (
    <div className="login">
      <Container>
        <Header heading="Terms and Conditions" />

        <Row className="text-white d-flex justify-content-center">
          <Col md={8}>
            <p>
              Coinvestment Options(bitcoin private) Privacy Policy Last Updated:
              October 18, 2018 Our website address is: Most Trusted Bitcoin
              Investment SitePrivacy is of utmost importance at the COINVEST
              OPTIONS group of companies (“Coinvestment Options”). We recognize
              the significance of protecting information which is stored on our
              computers or is intended to be stored on our computers and which
              relates to an individual. The data we protect are the “Personal
              Data” which is any information relating to an identified or
              identifiable natural person, sometimes called a data subject, and
              have made protecting privacy and the confidentiality of Personal
              Data a fundamental component of the way we do business. This
              Privacy Policy informs you of the ways we work to ensure privacy
              and the confidentiality of Personal Data. This policy describes
              the information we gather, how we use those Personal Data and the
              circumstances under which we disclose such information to third
              parties.
            </p>
            <p>
              In this Policy “Services” means the web site, any feature provided
              by us via that website or any local application (mobile desktop or
              otherwise), including without limitation investment services or
              Coinvestment Options information services, but excluding API
              services, which are governed by a separate agreement.
            </p>
            <p>
              If you do not agree with this Privacy Policy in general or any
              part of it, you should not use the Services.
            </p>
            <h4 className="text-danger">Comments</h4>
            <p>
              When visitors leave comments on the site we collect the data shown
              in the comments form, and also the visitor’s IP address and
              browser user agent string to help spam detection.
            </p>

            <h4 className="text-danger">Media</h4>
            <p>
              If you upload images to the website, you should avoid uploading
              images with embedded location data (EXIF GPS) included. Visitors
              to the website can download and extract any location data from
              images on the website.
            </p>
            <h4 className="text-danger">Cookies</h4>
            <p>
              If you leave a comment on our site you may opt-in to saving your
              name, email address and website in cookies. These are for your
              convenience so that you do not have to fill in your details again
              when you leave another comment. These cookies will last for one
              year.
            </p>
            <p>
              If you have an account and you log in to this site, we will set a
              temporary cookie to determine if your browser accepts cookies.
              This cookie contains no personal data and is discarded when you
              close your browser.
            </p>
            <p>
              When you log in, we will also set up several cookies to save your
              login information and your screen display choices. Login cookies
              last for two days, and screen options cookies last for a year. If
              you select “Remember Me”, your login will persist for two weeks.
              If you log out of your account, the login cookies will be removed.
            </p>
            <p>
              If you edit or publish an article, an additional cookie will be
              saved in your browser. This cookie includes no personal data and
              simply indicates the post ID of the article you just edited. It
              expires after 1 day.
            </p>
            <p>
              Embedded content from other websites Articles on this site may
              include embedded content (e.g. videos, images, articles, etc.).
              Embedded content from other websites behaves in the exact same way
              as if the visitor has visited the other website.
            </p>
            <p>
              These websites may collect data about you, use cookies, embed
              additional third-party tracking, and monitor your interaction with
              that embedded content, including tracking your interaction with
              the embedded content if you have an account and are logged in to
              that website.
            </p>
            <p> Bitcoin private is essential.</p>
            <h4 className="text-danger"> How long we retain your data</h4>

            <p>
              If you leave a comment, the comment and its metadata are retained
              indefinitely. This is so we can recognize and approve any
              follow-up comments automatically instead of holding them in a
              moderation queue.
            </p>
            <p>
              For users that register on our website (if any), we also store the
              personal information they provide in their user profile. All users
              can see, edit, or delete their personal information at any time
              (except they cannot change their username). Website administrators
              can also see and edit that information.
            </p>

            <h4 className="text-danger">
              What rights you have over your data(bitcoin private)
            </h4>
            <p>
              If you have an account on this site, or have left comments, you
              can request to receive an exported file of the personal data we
              hold about you, including any data you have provided to us. You
              can also request that we erase any personal data we hold about
              you. This does not include any data we are obliged to keep for
              administrative, legal, or security purposes.
            </p>
            <h4 className="text-danger">Where we send your data</h4>
            <p>
              Visitor comments may be checked through an automated spam
              detection service.
            </p>

            <h4 className="text-danger">Collection of Personal Information</h4>
            <p>
              When you access or use the Services, we collect the following
              information:
            </p>
            <p>
              Information you may provide to us: You may give us information
              about you by filling in forms on our website or through our app or
              by corresponding with us by phone, email or otherwise. This
              includes information you provide when you register to use the
              Services and when you report a problem with the website or on
              mobile devices.
            </p>
            <p>
              Information We Collect from Other Sources: We also receive
              information from other sources and combine that with information
              we collect through our Services. For instance:
            </p>
            <p>
              We use third-party services that may be co-branded as COINVEST
              OPTIONS but will do so with clear notice. Any third-party services
              may collect information as determined by their own privacy
              policies.
            </p>

            <h4 className="text-danger">
              Use of Personal Data(bitcoin private)
            </h4>
            <p>
              As it is in our legitimate interest to be responsive to you and to
              ensure the proper functioning of our products and organization, we
              will use your information to:
            </p>
            <p>
              Understand and meet your needs and preferences in using our
              Services;
            </p>
            <p>
              Develop new and enhance existing service and product offerings;
            </p>
            <p>Manage and develop our business and operations;</p>
            <p>
              Carry out any actions for which we have received your consent;
            </p>
            <p>Prevent fraudulent or other criminal activity; and</p>
            <p> Meet legal and regulatory requirements.</p>
            <p>
              We also reserve the right to use aggregated Personal Data to
              understand how our users use our Services, provided that those
              data cannot identify any individual.
            </p>
            <p>
              We also use third party web analytic tools that help us understand
              how users engage with the website. These third parties may use
              first-party cookies to track user interactions to collect
              information about how users use our website. This information is
              used to compile reports and to help us improve our website. The
              reports disclose website trends without identifying individual
              visitors. You can opt out of such third party analytic tools
              without affecting how you visit our site
            </p>
            <p>
              We will process your Personal Data legally and fairly and not use
              it outside the purposes of which we have informed you. So far as
              we are able we shall ensure that all of your Personal Data is
              accurate and up to date.
            </p>

            <h4 className="text-danger">
              Disclosure of Personal Data (bitcoin private)
            </h4>
            <p>
              We may share your information with selected recipients to perform
              functions required to operate your investment on our behalf. All
              such third parties will be contractually bound to protect data in
              compliance with our Policy. The categories of recipients include:
            </p>
            <p>
              Companies within the Coinvestment Options corporate family located
              in the United States and the United Kingdom, in order to provide
              the Services to you.
            </p>
            <p>
              Cloud services providers to store certain personal data and for
              disaster recovery services, as well as for the performance of any
              contract we enter into with you. We also may share personal
              information with your investment/trading broker in the event of a
              merger, divestiture, restructuring, reorganization, dissolution or
              other sale or transfer of some or all of Coinvestment Options
              assets, whether as a going concern or as part of bankruptcy,
              liquidation or similar proceeding, in which Personal Data held by
              COINVESTMENT OPTIONS is among the assets transferred.
            </p>
            <p>
              Except where we are required by law to disclose Personal Data or
              are exempted from, released from or not subject to any legal
              requirement concerning the disclosure of Personal Data, we will
              require any person to whom we provide your Personal Data to agree
              to comply with our Privacy Policy in force at that time or
              requirements substantially similar to such policy. We will make
              reasonable commercial efforts to ensure that they comply with such
              policy or requirements, however, where not expressly prohibited by
              law, we will have no liability to you if any person fails to do
              so. We shall require any third party, including without limitation
              any government or enforcement entity, seeking access to data we
              hold to have obtained a Court Order, or equivalent proof that they
              are statutorily empowered to access your data and that their
              request is valid and within their power.
            </p>

            <h4 className="text-danger">Retention of Your Personal Data</h4>
            <p>
              The length of time we retain Personal Data outside our back up
              system varies depending on the purpose for which it was collected
              and used, as follows:
            </p>
            <p>
              Data you provide to us when subscribing for our services: while
              user remains active, stored in the UK.
            </p>
            <p>
              Country Location data: while user remains active, stored in the
              UK. Data on your preferences: while user remains active, stored in
              the UK. IP address login: until subsequent login from a new IP,
              stored in the UK. Except where prohibited by law, this period may
              extend beyond the end of the particular relationship with us but
              only for so long as we are contractually bound to do so, or so far
              as is necessary for audit, regulatory or other accounting
              purposes. When Personal Data are no longer needed we have
              procedures either to destroy, delete, erase or convert it to an
              anonymous form.
            </p>
            <p>
              After you have terminated your use of our Services, we reserve the
              right to maintain your Personal Data as part of our standard
              back-up procedures in an aggregated format
            </p>

            <h4 className="text-danger">
              Storage of Personal Data(bitcoin private)
            </h4>
            <p>
              Coinvestment Options stores your Personal Data at secure locations
              in the EU. Coinvestment Options has ensured that appropriate
              security standards are in place regarding the safeguarding,
              confidentiality and security of Data. The information that we
              collect from you will be transferred to, and stored in,
              destinations outside of your country and the European Economic
              Area (“EEA”) as described below:
            </p>
            <p>
              The information that we collect from you will be transferred to,
              and stored in, destinations outside of your country and the
              European Economic Area (“EEA”) as described below:
            </p>
            <p>
              <strong>Adequacy Decision:</strong> The personal data that we
              collect from you will be transferred to, and stored at/processed
              in, the UK via encrypted communications channels and stored on
              hardware owned by Coinvestment Options for the purpose of
              operation of Services and disaster recovery, which were found to
              have an adequate level of protection for personal data under
              Commission Decision 2000/518/EC of 26 July 2000.
            </p>
            <p>
              <strong>Model Clauses:</strong> The personal data that we collect
              from you will be transferred to, and stored at/processed in the UK
              via encrypted communications channels and stored on hardware owned
              by Coinvestment Options for the purpose of operation of Services
              and disaster recovery, under the Commission’s model contracts for
              the transfer of personal data to third countries (i.e., the
              standard contractual clauses), pursuant to Decision 2010/87/EU.
            </p>
            <p>
              <strong>Privacy Shield(bitcoin private):</strong> The personal
              data that we collect from you will be transferred to, and stored
              at/processed by Coinvestment Options which complies with the US
              Department of Commerce’s EU-US Privacy Shield and Swiss–US Privacy
              Shield and has certified that we adhere to the EU-US Privacy
              Shield Principles of Notice, Choice, Accountability for Onward
              Transfer, Security, Data Integrity and Purpose Limitation, Access,
              Recourse, Enforcement and Liability. For more information about
              the EU-US Privacy Shield Framework and Swiss-US Privacy Shield
              Framework, visit the U.S. Department of Commerce’s Privacy Shield
              website.
            </p>
            <h4 className="text-danger">Your Rights</h4>
            <p>
              If you are resident in the EU, in certain circumstances you have
              the right to access and receive a copy of information we hold
              about you, to rectify any personal data held about you that is
              inaccurate and to request the deletion of personal data held about
              you. Any access request after the first such request by you may be
              subject to a reasonable fee to meet our costs in providing you
              with details of the information we hold about you. You also have
              the right to data portability for information you provide to us –
              this means that you can obtain a copy of your data in a commonly
              used electronic format so that you can manage and move it. You may
              have the right to restrict or object to the processing of your
              personal data by us.
            </p>
            <h4 className="text-danger">Acceptance</h4>
            <p>
              By using the Services, you signify your agreement to this Policy.
              Coinvestment Options reserves the right to change this Policy at
              any time. If we make any material changes to this Policy, the
              revised Policy will be posted here and notified to our users at
              least 30 days prior to the changes taking effect, so that you are
              always aware of what information we collect, how we use it and
              under what circumstances we disclose it. Please check this page
              frequently to see any updates or changes to this Policy
            </p>
            <h4 className="text-danger">Questions and Complaints</h4>
            <p>
              Any questions about this Policy, the collection, use and
              disclosure of Personal Data by Coinvestment Options or access to
              your Personal Data which is required by law (or would be so
              subject had the storage in question taken place in a relevant EU
              member state if the case may be but not otherwise) to be disclosed
              should be directed to support@coinvestmentoptions.net"
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default index;
