import React from "react";
import Header from "../Sections/Header";
import { Col, Row, Container } from "react-bootstrap";

const Referral = () => {
  return (
    <div className="referral-commission">
      <Container>
        <Header
          paragraph="You can increase your funds by referring your friends to invest"
          heading="Refferal Commission"
        />

        <Row className="no-gutters justify-content-center">
          <Col lg={4} md={4} sm={5} xs={10} data-aos="flip-left">
            <div className="single-level">
              <span className="number">10%</span>
              <span className="level">On First Deposit</span>
            </div>
          </Col>

          <Col lg={4} md={4} sm={5} xs={10} data-aos="flip-down">
            <div className="single-level second">
              <span className="number">8%</span>
              <span className="level">On Second Deposit</span>
            </div>
          </Col>

          <Col lg={4} md={4} sm={5} xs={10} data-aos="flip-right">
            <div className="single-level third">
              <span className="number">5%</span>
              <span className="level">On Third Deposit</span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Referral;
