import React from "react";
import Slide from "../Widgets/SlidePrice";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import Link from "react-router-dom/Link";
import titleImg from "../../assets/img/coinvestoptions.png";

class Header extends React.Component {
  render() {
    return (
      <div className="header homepage">
        <Container>
          <Row>
            <Col>
              <Navbar bg="transparent" expand="lg" sticky="top" variant="dark">
                <Navbar.Brand href="/" className="logo">
                  <img src={titleImg} width="100%" alt={"title"} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                      <Link className="nav-link" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Nav.Link className="nav-link" href="/contact">
                        Support
                      </Nav.Link>
                    </li>

                    <li className="nav-item">
                      <Nav.Link className="nav-link" href="/about">
                        About
                      </Nav.Link>
                    </li>
                    <li className="nav-item">
                      <Nav.Link className="nav-link" href="/faq">
                        FAQs
                      </Nav.Link>
                    </li>
                    <li className="nav-item">
                      <Nav.Link href="/investment" className="nav-link">
                        Packages
                      </Nav.Link>
                    </li>
                   
                    <li className="login-button">
                      <a href="/register">Sign Up</a>
                    </li>
                    <li className="login-button">
                      <a href="/login">Log In</a>
                    </li>
                  </ul>
                </Navbar.Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
        <Slide />
      </div>
    );
  }
}
export default Header;
