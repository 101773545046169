import React, { Component } from "react";
import Header from "../.././components/Header/Header";
import Message from "../.././components/Message/Message";
import BreadCrumb from "../.././components/BreadCrumb/BreadCrumb";
import Footer from "../.././components/Footer/Footer";

export default class Contact extends Component {
	render() {
		return (
			<div>
				<Header />
				<BreadCrumb title="Contact" />
				<Message />
				<Footer />
			</div>
		);
	}
}
