import React from "react";
import Navigation from "../../../components/Account/Navigation/Navigation";
import "../../../assets/Account/css/style.css";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../../components/Account/Navigation/Footer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import API from "../../../API";

class Withdrawal extends React.Component {
  constructor() {
    super();
    this.state = {
      wallet: "",
      type: "Withdraw",
      amount: 0,
      plan: "",
      errorMessage: "",
    };
    this.onWithdrawSubmit = this.onWithdrawSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ target: { name, value } }) {
    this.setState({
      [name]: value,
    });
    console.log(this.state);
  }
  componentDidMount() {
    API.get("verify?token=" + window.localStorage.getItem("token"))
      .then((res) => {
        const data = res.data;
        if (data.success === false) {
          setTimeout(() => {
            window.location = "/";
          }, 1000);
        } else {
          this.setState({ wallet: data.wallet });
          this.setState({ plan: data.plan });
        }
      })
      .catch((err) => {
        this.setState({ errorMessage: "There has been a server error" });
      });
  }

  onWithdrawSubmit() {
    if (this.state.wallet === "") {
      window.location.href = "/account/settings";
    } else {
      API.post("transaction/withdraw/?token=" + localStorage.getItem("token"), {
        amount: this.state.amount,
        plan: this.state.plan,
        type: this.state.type,
      })
        .then((res) => {
          const data = res.data;
          if (data.success === true) {
            window.location.href = "/account/transactions";
          } else {
            this.setState({ errorMessage: data.message });
          }
        })
        .catch((err) => {
          this.setState({
            errorMessage: "There has been an error making your withdrawal",
          });
        });
    }
  }

  render() {
    const { wallet } = this.state;
    return (
      <div className="deposit">
        <Navigation />
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <Card className="account-cards">
                <Card.Body>
                  <Card.Title> Withdraw</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    How much do you want to withdraw?
                  </Card.Subtitle>

                  <Form>
                    <p className="text-danger">{this.state.errorMessage}</p>
                    <Form.Group controlId="WithdrawAmount">
                      <Form.Control
                        type="text"
                        placeholder="Enter amount you would like to withdraw"
                        onChange={this.handleChange}
                        name="amount"
                      />
                    </Form.Group>
                    <Form.Group controlId="SelectWithdrawWallet">
                      <Form.Label>Select Wallet Address</Form.Label>
                      <Form.Control
                        as="select"
                        name="wallet"
                        onChange={this.handleChange}
                      >
                        {wallet ? (
                          <option value={wallet}>{wallet}</option>
                        ) : (
                          <option value="select">Add Wallet</option>
                        )}
                      </Form.Control>
                    </Form.Group>

                    <span className="note-text text-danger">
                      <i className="fas fa-times-circle text-danger"></i> Do not
                      request for withdrawals greater than your balance
                    </span>
                    <br />
                    <Button
                      variant="danger"
                      className="add-wallet-details"
                      onClick={this.onWithdrawSubmit}
                    >
                      <i className="fab fa-btc "></i> Withdraw
                    </Button>
                    <Link to="/account/settings" className="text-white">
                      <button className="btn btn-info my-3">Add Wallet</button>
                    </Link>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Withdrawal;
