import React, { Component } from "react";
import Navigation from "../../../components/Admin/Navigation";
import Email from "../../../components/Admin/Email";

export class index extends Component {
	render() {
		return (
			<div className="Admin">
				<Navigation />

				<Email />
			</div>
		);
	}
}

export default index;
