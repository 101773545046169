import React from "react";


const BreadCrumb = props => {
	return (
		<div className="breadcrump">
			<div className="container">
				<div className="row">
					<div className="col-xl-6 col-lg-6">
						<div className="breadcrump-content">
							<h2>{props.title}</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default BreadCrumb;
