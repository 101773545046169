import React from "react";
import { Card, Col } from "react-bootstrap";

const CardFormat = ({ top, center, bottom, title }) => (
  <Col md={4}>
    <Card className="card--container">
      <Card.Body className="text-center">
        <Card.Title className="type">{title}</Card.Title>
        <Card.Text>
          Available Balance: <h1>${top}</h1>
        </Card.Text>

        <Card.Text className="text-muted">Current Plan : {center}</Card.Text>
        <Card.Text className="text-muted">Book Balance : ${bottom}</Card.Text>
      </Card.Body>
    </Card>
  </Col>
);
export default CardFormat;
