import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Header from "../Sections/Header";
import API from "../../API";

class Register extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoading: false,
      email: "",
      password: "",
      confirmPassword: "",
      samePassword: true,
      first_name: "",
      last_name: "",
      errorMessage: "",
      passwordType: "password",
      confirmType: "password",
    };
    this.onConfirmPassword = this.onConfirmPassword.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  showPassword = ({ target: { name, type } }) => {
    if ([type] === "password") this.setState({ [name]: "text" });
    else this.setState({ [name]: "password" });
    console.log(type);
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onConfirmPassword = ({ target: { value } }) => {
    if (value === this.state.password) this.setState({ samePassword: true });
    else this.setState({ samePassword: false });

    this.setState({ confirmPassword: value });
  };
  onRouteChange = (event) => {
    window.location.href = "/login";
  };

  onRegister = () => {
    this.setState({ isLoading: true });
    const { email, password, first_name, last_name } = this.state;
    const userData = { email, password, first_name, last_name };
    try {
      API.post("register/", { userData }).then((res) => {
        const data = res.data;
        if (data.success === true) {
          this.onRouteChange();
        } else {
          this.setState({ errorMessage: data.message });
          this.setState({ isLoading: false });
        }
      });
    } catch (error) {
      console.log(error);
      this.setState(
        { isLoading: false },
        { errorMessage: "Your registration process failed" }
      );
    }
  };

  render() {
    let { isLoading, errorMessage, confirmType, passwordType } = this.state;
    return (
      <div className="register">
        <Container>
          <Header
            paragraph="sign up a free account and join thousands of investors community members"
            heading="Register"
          />

          <Row className="d-flex justify-content-center align-items-center">
            <Col md={8}>
              <Card className="register-card" data-aos="fade-up">
                <Card.Body>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="pb-1">
                          <Form.Label
                            className="font-weight-bold"
                            htmlFor="first_name"
                          >
                            <span>
                              <i className="fas fa-id-card text-info"></i>
                            </span>{" "}
                            First Name: <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            id="first_name"
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            onChange={this.onChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="pb-1">
                          <Form.Label
                            className="font-weight-bold"
                            htmlFor="last_name"
                          >
                            <span>
                              <i className="fas fa-id-card text-info"></i>
                            </span>{" "}
                            Last Name: <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            id="last_name"
                            name="last_name"
                            type="text"
                            placeholder="Last Name"
                            onChange={this.onChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group className="pb-1">
                      <Form.Label
                        className="font-weight-bold"
                        htmlFor="reg-email"
                      >
                        <span>
                          <i className="fas fa-envelope text-info"></i>
                        </span>{" "}
                        E-mail: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        id="reg-email"
                        name="email"
                        type="text"
                        placeholder="E-mail"
                        onChange={this.onChange}
                      />
                    </Form.Group>
                    <Form.Group className="pb-1">
                      <Form.Label
                        className="font-weight-bold"
                        htmlFor="secret-key"
                      >
                        <span>
                          <i className="fas fa-lock text-info"></i>
                        </span>{" "}
                        Password: <span className="text-danger">*</span>{" "}
                        <span>
                          <div
                            style={{
                              display: "inline",
                              border: "none",
                              background: "transparent",
                            }}
                            onClick={this.showPassword}
                          >
                            <i className="fas fa-eye"></i>
                          </div>
                        </span>
                      </Form.Label>
                      <Form.Control
                        id="secret-key"
                        name="password"
                        type={passwordType}
                        placeholder="Secret Key"
                        onChange={this.onChange}
                      />
                    </Form.Group>
                    <Form.Group className="pb-1">
                      <Form.Label
                        className="font-weight-bold"
                        htmlFor="confirm-secret-key"
                      >
                        <span>
                          <i className="fas fa-lock text-info"></i>
                        </span>{" "}
                        Confirm Password: <span className="text-danger">*</span>{" "}
                        <span>
                          <div
                            style={{
                              display: "inline",
                              border: "none",
                              background: "transparent",
                            }}
                            onClick={this.showPasswordConfirm}
                          >
                            <i className="fas fa-eye"></i>
                          </div>
                        </span>
                      </Form.Label>
                      <Form.Control
                        id="confirm-secret-key"
                        name="confirmType"
                        type={confirmType}
                        placeholder="Confirm Secret Key"
                        onChange={this.onConfirmPassword}
                      />

                      {!this.state.samePassword && (
                        <span className="text-danger font-weight-bold my-2">
                          Password Don't Match
                        </span>
                      )}
                    </Form.Group>
                    <Col md={12}>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="customCheckRegister"
                          type="checkbox"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted font-weight-bolder">
                            I agree with the{" "}
                            <Link to="/privacy">
                              <span className="text-success">
                                Privacy Policy
                              </span>
                            </Link>
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Form>
                  <div className="error text-danger">
                    <p>{errorMessage}</p>
                  </div>

                  <button
                    disabled={isLoading}
                    onClick={this.onRegister}
                    className="blueBtn"
                  >
                    {isLoading && (
                      <div>
                        <Spinner
                          animation="border"
                          role="status"
                          style={{ height: "20px", width: "20px" }}
                        ></Spinner>{" "}
                        Signing
                      </div>
                    )}
                    {!isLoading && (
                      <span style={{ color: "white" }}>Register</span>
                    )}
                  </button>
                  <Link to="/login" className="haveAccount">
                    or Have an account?
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Register;
