import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Banner = () => {
  return (
    <div className="banner">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-8 col-lg-10">
            <div className="banner-content" data-aos="zoom-out">
              <div className="part-text">
                <h2>
                  The most convenient way to buy, save & invest cryptocurrency
                </h2>
                <p>
                  Coinvestment Options is a VIP Crypto Currency investment
                  platform that utilizes innovative proprietary technologies to
                  provide management to crypto currencies and trading services
                  to yield higher profits within limited period.
                </p>
                <div className="banner-buttons" data-aos="fade-up">
                  <a href="/register">Join Now</a>
                  <a href="/login">Invest</a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Banner;
